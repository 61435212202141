import React, {Fragment, useEffect} from "react";
import {Table, Spinner} from 'react-bootstrap';
import './style.scss';
import {localTime, minutesToMiliseconds} from "../../../utils/time-converter.utils";
import SportEvent from "../../models/sport-event";
import {connect} from "react-redux";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import WatchColumn from "./children/watch-column/watch-column";
import {isNewEvent, isOpenEvent} from "../../services/shared.service";
import {isEdge} from "../../../utils/user-agent";
import uuid from "uuid";
import {toastr} from 'react-redux-toastr'
import moment from "moment";
import SportradarRow from "./children/sportradar-row";
import {Role} from "../../../router/roles";
import {ScheduleTableBodyProps, ScheduleTableBodyState, mapStateToProps, mapDispatchToProps} from "./infrastructure";
import {Restriction} from "../../../common/types/restriction.type";
import ResizeObserver from 'resize-observer-polyfill';

class ScheduleTableBody extends React.Component<ScheduleTableBodyProps, ScheduleTableBodyState> {
    timer: any;
    RERENDER_INTERVAL = minutesToMiliseconds(0.5);
    observer: any;
    ref: any;
    infoCellRef: any;

    shouldScrollToTop: boolean = false;
    shouldScrollToBottom: boolean = false;
    basicMargin = 140;

    constructor(props) {
        super(props);
        this.state = {
            scrollY: 1,
            prevY: 0,
            selectedDate: moment(this.props.selectedDate),
            selectedDateBottom: moment(this.props.selectedDate),
            selectedDateTop: moment(this.props.selectedDate),
            topLoading: false,
            bottomLoading: false,
            topElementRefLabel: '',
            popupRef: null,
            infoCellWidth: 0
        }
        this.ref = React.createRef();
        this.infoCellRef = React.createRef<HTMLElement>();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.forceUpdate(), this.RERENDER_INTERVAL)
        this.props.getAcceptedServerNames();
        this.setupTopEventListener();

        if (this.infoCellRef.current !== null) {
            const infoCell = this.infoCellRef.current;
            const observer = new ResizeObserver(() => {
                this.setState({infoCellWidth: this.infoCellRef.current!.clientWidth})
            });
            observer.observe(infoCell);
        }
    }

    almostLastEvent: any;
    lastRememberedEvent: any;

    componentWillReceiveProps(props: ScheduleTableBodyProps) {
        if (this.infoCellRef.current !== null) this.setState({infoCellWidth: this.infoCellRef.current!.clientWidth});

        if (!props.events.isTopScrollLoading && this.props.events.isTopScrollLoading) {
            this.shouldScrollToTop = true;
        }
        if (!props.events.isBottomScrollLoading && this.props.events.isBottomScrollLoading) {
            this.shouldScrollToBottom = true;
        }

        if (props.events.source && props.events.source.length) {
            this.almostLastEvent = props.events.source[props.events.source.length - 3];
        }

        const isScrollTriggered = (props.events.isTopScrollLoading
            || this.props.events.isTopScrollLoading
            || props.events.isBottomScrollLoading
            || this.props.events.isBottomScrollLoading);

        this.setState({topLoading: props.events.isTopScrollLoading, bottomLoading: props.events.isBottomScrollLoading})

        if (moment(this.props.selectedDate).isSame(moment(props.selectedDate), 'day')) {
            return;
        }

        if (isScrollTriggered && this.props.events.source && this.props.events.source!.length) {
            const firstEventDate = moment(this.props.events.source![0].startTime);
            const lastEventDate = moment(this.props.events.source![this.props.events.source!.length - 1].startTime);

            this.setState({
                selectedDateTop: moment(firstEventDate),
                selectedDateBottom: moment(lastEventDate)
            })
        }

        if (!isScrollTriggered) {
            this.setState({
                selectedDate: moment(props.selectedDate),
                selectedDateTop: moment(props.selectedDate),
                selectedDateBottom: moment(props.selectedDate)
            })
        }

        this.setState({
            selectedDate: moment(props.selectedDate),
        })
    }

    componentDidUpdate() {
        if (this.shouldScrollToTop) {
            const topPoint = this.props.refs[this.state.topElementRefLabel].current.offsetTop as number;
            this.scrollTo(topPoint);
            this.shouldScrollToTop = false;
        }
        if (this.shouldScrollToBottom) {
            const link = `${this.lastRememberedEvent.eventId.toString()} ${this.lastRememberedEvent.startTime.toString()}`;
            ;
            const topPoint = this.props.refs[link].current.offsetTop as number;
            this.scrollTo(topPoint);
            this.shouldScrollToBottom = false;
        }
    }

    scrollToEvent = (event: SportEvent) => {
        const refLabel = `${event.eventId.toString()} ${event.startTime.toString()}`;

        if (Object.keys(this.props.refs).length === 0 || !this.props.refs[refLabel]) {
            return;
        }

        const topPoint = this.props.refs[refLabel].current.offsetTop as number;

        this.scrollTo(topPoint);
    }

    scrollTo = (positionTop: number) => {
        const el = (document.getElementById('table-container') as any);

        if (isEdge()) {
            el.scrollTop = positionTop - 49;
            return;
        }

        el.scrollTo({
            top: positionTop - 49,
        })
    }

    handleObserver(entities) {
        if (this.props.filters.isApplied) {
            return;
        }
        const {user} = this.props;
        const restriction = user!.restriction as Restriction;

        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y && this.props.events.source!.length && !this.props.events.dontHaveEventsAnymore) {
            const curDate = this.state.selectedDateBottom.add(1, "d");
            this.props.getSchedulesDueToInfiniteScroll({selectedDate: curDate, restriction})
            this.setState({selectedDateBottom: curDate});
        }
        this.setState({prevY: y});
    }

    setupTopEventListener() {
        const el = document.getElementById('table-container') as HTMLElement;
        const {user} = this.props;

        el.addEventListener('scroll', () => {

            if (this.props.filters.isApplied) {
                return;
            }

            if (el.scrollTop === 0 && this.props.events.source!.length) {
                const curDate = this.state.selectedDateTop.add(-1, "d");
                this.setState({selectedDateTop: curDate, topElementRefLabel: Object.keys(this.props.refs)[0]});
                this.props.getPrevSchedulesDueToInfiniteScroll({
                    selectedDate: curDate,
                    restriction: user!.restriction as Restriction
                });
            }

            if (el.scrollTop + el.clientHeight === el.scrollHeight && this.props.events.source!.length && !this.props.events.dontHaveEventsAnymore) {
                this.lastRememberedEvent = this.almostLastEvent;
                if (!this.props.events.isBottomScrollLoading) {
                    const curDate = this.state.selectedDateBottom.add(1, "d");
                    this.props.getSchedulesDueToInfiniteScroll({
                        selectedDate: curDate,
                        restriction: user!.restriction as Restriction
                    });
                    this.setState({selectedDateBottom: curDate});
                }
            }

        })
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    setTableHeight = (isOpenSkyBoxes: boolean, boxPanelHeight: number, windowHeight) => {
        if (this.props.user && (this.props.user!.restriction.isSkyBoxesAllowed || this.props.user!.restriction.isInternalStreamsAllowed)) {

            return windowHeight - boxPanelHeight - 65;
        } else {
            return windowHeight - 65;
        }
    }

    render() {

        return (
            <div
                id='table-container'
                className={this.props.isExpanded ? 'fix-table' : ''}
                style={{
                    height: this.setTableHeight(
                        this.props.isOpenSkyBoxes,
                        this.props.boxPanelHeight,
                        this.props.windowHeight),
                    marginTop: 0
                }}
            >
                {
                    this.props.isExpanded && (
                        <Fragment>
                            <Table
                                className="schedule-table table table-striped table-dark table-hover"
                                striped
                                bordered
                                hover
                            >
                                <thead>
                                <tr className="table-head">
                                    <th scope="col" className="start-time">
                                        Start Date
                                    </th>
                                    <th scope="col">Content Provider</th>
                                    <th scope="col">Sport</th>
                                    <th scope="col">Competition</th>
                                    <th scope="col">Information</th>
                                    <th scope="col">Home Team</th>
                                    <th scope="col">Away Team</th>
                                    <th scope="col">Watch</th>
                                </tr>
                                </thead>
                                <tbody id="schedule">
                                {this.renderLoadingSpinner('table-row', this.state.topLoading, false)}
                                {this.renderTableBody()}
                                {this.renderLoadingSpinner('table-row', !this.props.events.dontHaveEventsAnymore && this.state.bottomLoading || this.props.events.isLoading, false)}
                                {this.renderNoEventsLabel(this.props.events.dontHaveEventsAnymore)}
                                </tbody>
                            </Table>
                        </Fragment>
                    )
                }
            </div>
        );
    }

    renderLoadingSpinner(display: string, condition: boolean, isRef: boolean) {
        return (
            <tr
                style={{
                    display: display,
                    width: '100%'
                }}>
                <td
                    ref={isRef ? ref => (this.ref = ref) : null}
                    colSpan={8}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <Spinner animation="border" style={{
                        display: condition ? 'inline-block' : 'none'
                    }}/>
                </td>
            </tr>
        )
    }

    renderNoEventsLabel(condition?: boolean) {
        return (
            <tr
                style={{
                    display: 'table-row',
                    width: '100%'
                }}>
                <td
                    colSpan={8}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <div style={{
                        display: condition ? 'inline-block' : 'none'
                    }}>No events for the next day...
                    </div>
                </td>
            </tr>
        )
    }

    renderEmptyTable() {
        return (
            <tr style={{background: 'border-box', textAlign: 'center'}}>
                <td colSpan={7}>No Events Scheduled</td>
            </tr>
        );
    }

    renderTooltip(text: string, channel: string) {
        const getImage = (text) => {
            switch (text) {
                case 'InPlay':
                    return './service-icons/inplay.png';
                case 'InPlay Latent':
                    return './service-icons/latent.png'
                case 'Stats Perform':
                    return './service-icons/statsPerform.png';
                case 'XVI':
                    return './service-icons/xvi.png';
                case 'Modus':
                    return './service-icons/modus.svg';
                case 'Internal Schedule':
                    return './service-icons/internalSchedule.png';
                case 'Inplay Radio':
                    return './service-icons/inplayRadio.png'
                case 'Spring Media':
                    return './service-icons/springMedia.png'
                case 'Infront':
                    return './service-icons/infront.png'
                case 'CSM':
                    return './service-icons/csm.png'
                case 'TDI':
                    return './service-icons/tdi.png'
                case 'Trinta':
                    return './service-icons/trinta.png'
                case 'BOXXER':
                    return './service-icons/boxxer.png'
                case 'SailGP':
                    return './service-icons/saleGPlogo.png'
            }
        }

        return (
            <Tooltip placement='top' trigger={'hover'} overlay={<span>{`${channel}`}</span>}>
                <img src={getImage(text)}/>
            </Tooltip>
        )
    }

    isPlayerSelectable = (playerNumber: number) => {
        return playerNumber > this.props.streamSettings.filter(x => !x.isVod).length + 1;
    }

    selectVod = (link: string, event: SportEvent, isHd: boolean) => {
        if (event.isRestricted) {
            return;
        }

        if (!link) {
            return;
        }

        this.props.selectVod(link, event, isHd);
    }

    renderRtmpTooltip = (link: string) => {
        const pointerStyle = {cursor: 'pointer'};
        const id = uuid();

        if (!link) {
            return;
        }

        if (isEdge()) {
            const tooltip = (
                <span style={pointerStyle}>
                    <span>Copy RTMP</span>
                    <textarea
                        style={{position: 'absolute', top: '-1000px', opacity: 0}}
                        readOnly={true}
                        defaultValue={link}
                        id={id}>
                    </textarea>
                </span>);

            return (
                <Tooltip
                    placement='top'
                    trigger={'hover'}
                    overlay={tooltip}>
                    <i className="fas fa-link" onClick={() => {
                        const tt: any = document.getElementById(id);
                        tt.focus();
                        tt.select()
                        document.execCommand('copy');
                        this.props.addRtmpLink(link);
                    }
                    } style={{marginRight: '10px', ...pointerStyle}}></i>
                </Tooltip>
            )
        }

        return (
            <Tooltip
                placement='top'
                trigger={'hover'}
                overlay={<span style={pointerStyle}> Copy RTMP</span>}>
                <i className="fas fa-link" onClick={() => {
                    navigator.clipboard.writeText(link);
                    this.props.addRtmpLink(link);
                    toastr.light('RTMP link is copied...', '', {timeOut: 2000});
                }}
                   style={{marginRight: '10px', ...pointerStyle}}>
                </i>
            </Tooltip>
        )
    }

    setPopupRef = (ref: any) => {
        this.setState({popupRef: ref})
    }

    renderTableBody() {
        const events: SportEvent[] = this.props.filters.isApplied
            ? this.props.filteredEvents
            : this.props.events.source as SportEvent[];

        if ((!events || events.length === 0) && !this.props.events.isLoading) {
            return this.renderEmptyTable();
        }

        return events!.map((item: SportEvent, i: number) => {
            const startTime = localTime(item.startTime)
            const isEventNew = isNewEvent(item);

            return (
                item.service === 'Sportradar'
                    ? <SportradarRow
                        item={item}
                        key={i}
                        startTime={startTime}
                        refs={this.props.refs}
                    />
                    : <tr
                        className={`${item.isRestricted ? "event-row-restricted" : "event-row"}`}
                        key={i}
                        start-time={startTime}
                        ref={this.props.refs[`${item.eventId.toString()} ${item.startTime.toString()}`]}
                    >
                        <td className="start-time">
                            <span>
                                {
                                    this.props.user!.restriction.isRtmpEnabled && isOpenEvent(item)
                                    && [Role.SuperAdmin, Role.Admin, Role.ClientAdmin, Role.ClientSeniorAnalyst]
                                        .includes(this.props.user!.role)
                                    && this.renderRtmpTooltip(item.rtmpLink)
                                }
                                {startTime.format('D/MM/YYYY HH:mm')}
                            </span>
                        </td>
                        <td className="latency">
                            {this.renderTooltip(item.service, item.channel)}
                        </td>
                        <td className='sport-column' title={item.sport}>
                            {item.sport}
                            {isEventNew && <i className="fas fa-star"></i>}
                        </td>
                        <td className="competition-column">
                            <img
                                alt={item.countryCode}
                                src={`./countryflags/${item.countryCode}.gif`}
                                style={{marginRight: '5px', paddingBottom: '3px'}}
                            />
                            {item.competition}
                        </td>
                        {
                            this.state.infoCellWidth / (item.information ? item.information.length : 0) < 6.5
                                ?
                                <Tooltip placement='top' trigger={'hover'} overlay={<span>{`${item.information}`}</span>}>
                                    <td className="information-column" ref={this.infoCellRef}>{item.information}</td>
                                </Tooltip>
                                :
                                <td className="information-column" ref={this.infoCellRef}>{item.information}</td>
                        }
                        <td className="home-team-column">{item.competitor1}</td>
                        <td className="away-team-column">{item.competitor2}</td>
                        <td align="center" className="watch-col">
                            <div className="d-flex">
                                {
                                    <WatchColumn
                                        isPlayerSelectable={this.isPlayerSelectable.bind(this)}
                                        item={item}
                                        selectStream={this.props.selectStream.bind(this)}
                                        selectVod={this.selectVod}
                                        user={this.props.user}
                                        setPopupRef={this.setPopupRef}
                                        popupRef={this.state.popupRef}
                                        streamSettings={this.props.streamSettings}
                                    />
                                }

                            </div>
                        </td>
                    </tr>
            );
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableBody);
